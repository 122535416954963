import './bbcl'

window.BBCL.mixins.push({
    data() {
        return {
            ads: {}
        }
    }
})

window.BBCL.plugins.push((Vue, store) => {
    store.registerModule('BBCL_HEADER', {
        namespaced: true,
        state: function() {
            return {
                showHiddenNav: false,
                showNews: false,
                showSearch: false,
                showLittleNavbar: false
            }
        },
        mutations: {
            SET_SHOW_HIDDEN_NAV: (state, show) => {
                state.showHiddenNav = show
            },
            SET_SHOW_NEWS: (state, show) => {
                state.showNews = show
            },
            SET_SHOW_SEARCH: (state, show) => {
                state.showSearch = show
            },
            SET_SHOW_LITTLE_NAVBAR: (state, show) => {
                state.showLittleNavbar = show
            },
            TOGGLE_HIDDEN_NAV: state => {
                state.showHiddenNav = !state.showHiddenNav
            },
            TOGGLE_NEWS: state => {
                state.showNews = !state.showNews
            },
            TOGGLE_SEARCH: (state, focusableSearchInput) => {
                state.showSearch = !state.showSearch

                if (state.showSearch && focusableSearchInput) {
                    const searchInput = document.getElementById(focusableSearchInput)

                    if (searchInput) {
                        searchInput.focus()
                    }
                }
            }
        }
    })

    store.watch(
        state => state.BBCL_HEADER.showHiddenNav,
        show => {
            if (!show) {
                store.commit('BBCL_HEADER/SET_SHOW_NEWS', false)
            }
        }
    )

    store.watch(
        state => state.BBCL_HEADER.showNews,
        show => {
            if (show) {
                store.commit('BBCL/CLEAR_NOTIFICATION_COUNT')
            }
        }
    )

    window.addEventListener('scroll', () => {
        if (window.scrollY > 500) {
            if (!store.state.BBCL_HEADER.showLittleNavbar) {
                store.commit('BBCL_HEADER/SET_SHOW_LITTLE_NAVBAR', true)
            }
        } else if (store.state.BBCL_HEADER.showLittleNavbar) {
            store.commit('BBCL_HEADER/SET_SHOW_LITTLE_NAVBAR', false)
        }
    })
})

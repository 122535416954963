export default {
    bind(el, { value }) {
        if (typeof value === 'string') {
            el._$vCopyValue = value
        } else {
            el._$vCopyValue = value.text
            el._$vCopyCallback = value.callback
        }

        const handler = event => {
            try {
                var tempInput = document.createElement('input')
                document.body.appendChild(tempInput)
                tempInput.value = el._$vCopyValue
                tempInput.select()
                document.execCommand('copy')
                tempInput.parentElement.removeChild(tempInput)
                event.preventDefault()

                if (el._$vCopyCallback instanceof Function) {
                    el._$vCopyCallback()
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            }
        }

        el.addEventListener('click', handler)
        el._$vCopyRemove = () => el.removeEventListener('click', handler)
    },
    update(el, { value, oldValue }) {
        if (value !== oldValue) {
            if (typeof value === 'string') {
                el._$vCopyValue = value
            } else {
                el._$vCopyValue = value.text
                el._$vCopyCallback = value.callback
            }
        }
    },
    unbind(el) {
        el._$vCopyRemove()
    }
}
